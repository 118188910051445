import React, { useContext, useState, useRef, useEffect, KeyboardEventHandler } from 'react';

// components
import { CloseIcon } from '@zola/zola-ui/src/components/SvgIcons/Close';
import { CaretV2Icon } from '@zola/zola-ui/src/components/SvgIcons/CaretV2';
import Portal from '@zola/zola-ui/src/components/util/Portal';
import VideoSnippet from 'components/util/VideoSnippet';
import TitleImgDesc from 'components/publicWebsiteV2/common/TitleImgDesc';
import SinglePageGalleryPhotos from 'components/publicWebsiteV2/pages/Gallery/components/SinglePageGalleryPhotos';
import GalleryPhotos from 'components/publicWebsiteV2/pages/Gallery/components/GalleryPhotos';

// context
import { useWebsiteThemeContext } from 'components/publicWebsiteV2/context';
import { DeviceContext } from 'contexts/DeviceContext';

// types
import type {
  WCmsPhotoPageView,
  WCmsPhotoView,
  WAbsoluteThemeComponentAssetStyleView,
} from '@zola/svc-web-api-ts-client';

// hooks
import { useContainerDimensions } from '@zola/zola-ui/src/hooks/useContainerDimensions';

// utils
import getZolaImageFromUuid from '@zola-helpers/client/dist/es/util/getZolaImageFromUuid';

import { getImageRequestWidth } from 'components/publicWebsiteV2/util/getRelativeImgSize';
import { notMobile } from '@zola-helpers/client/dist/es/util/responsive';
import getNonHomeCmsHeroImg from 'components/publicWebsiteV2/util/getNonHomeCmsHeroImg';

// styles
import { ScreenReaderText } from '@zola/zola-ui/src/components/Modal/Modal.styles';
import { PageAnimationWrapper } from 'components/publicWebsiteV2/common/PageAnimationWrapper/PageAnimationWrapper';
import {
  CaretContainerButton,
  CloseLightboxBtn,
  GalleryPageContainer,
  Lightbox,
  LightboxCaption,
  LightboxImage,
  LightboxImageContainer,
  LightboxImageSection,
  NoVisuals,
  SlideshowContainer,
  VideosContainer,
  ControlContainer,
} from './Gallery.styles';

export type GalleryProps = {
  pageData?: WCmsPhotoPageView;
  isSinglePage?: boolean;
};

const localAssetStyleView = ({
  alt_text: 'galleryImg',
  responsive_size: {
    desktop: {
      width: {
        unit: 'PERCENTAGE',
        value: 70,
      },
    },
    mobile: {
      width: {
        unit: 'PERCENTAGE',
        value: 100,
      },
    },
  },
} as unknown) as WAbsoluteThemeComponentAssetStyleView;

const Gallery: React.FC<GalleryProps> = ({ pageData, isSinglePage }) => {
  const { device } = useContext(DeviceContext);
  const {
    state: {
      components: { styleCmsEntityBodyFont },
      inPreview,
    },
  } = useWebsiteThemeContext();

  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [openPhoto, setOpenPhoto] = useState<WCmsPhotoView | null>(null);
  const [portalContainer, setPortalContainer] = useState<HTMLElement | null>(null);

  const imageRef = useRef<HTMLImageElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const galleryRef = useRef(null);

  const { width: containerWidth } = useContainerDimensions(galleryRef);

  const calculatedWidth = getImageRequestWidth(localAssetStyleView, containerWidth, device);
  const URL_PARAMS = calculatedWidth === 0 ? '' : `w=${calculatedWidth}`;

  const CLOSE_ICON_SIZE = device?.notMobile() ? 30 : 24;
  const { title, description, photos = [], videos = [], header_image_url, images } = pageData || {};

  const cmsHeaderImgUrl = getNonHomeCmsHeroImg(images);

  const handleCloseLightbox = () => {
    setLightboxOpen(false);
    setCurrentIndex(0);
    setOpenPhoto(null);
    document.body.classList.remove('no-scroll');
  };

  const handlePressEsc: KeyboardEventHandler<HTMLDivElement> = e => {
    if (e.key === 'Escape') {
      e.preventDefault();
      e.stopPropagation();
      handleCloseLightbox();
    }
  };

  const handleOpenLightbox = (photo: WCmsPhotoView) => {
    if (notMobile()) {
      const idx = photos.indexOf(photo);
      setLightboxOpen(true);
      setOpenPhoto(photo);
      setCurrentIndex(idx);
      document.body.classList.add('no-scroll');
    }
  };

  const handlePrevImage = () => {
    if (currentIndex > 0) {
      setOpenPhoto(photos[currentIndex - 1]);
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNextImage = () => {
    setOpenPhoto(photos[currentIndex + 1]);
    setCurrentIndex(currentIndex + 1);
  };

  useEffect(() => {
    if (document) {
      const zolaWedContainer = document.getElementById('zola-wedding-container');
      if (zolaWedContainer) {
        setPortalContainer(zolaWedContainer);
      }
    }
  }, []);

  useEffect(() => {
    if (lightboxOpen) {
      containerRef?.current?.focus();
    }
  }, [lightboxOpen, containerRef]);

  const StyledNoVisuals = styleCmsEntityBodyFont(NoVisuals);

  return (
    <GalleryPageContainer ref={galleryRef} data-testid={isSinglePage ? 'GallerySP' : 'Gallery'}>
      <TitleImgDesc
        title={title}
        url={cmsHeaderImgUrl || header_image_url}
        description={description}
      />
      {!videos.length && !photos.length && <StyledNoVisuals>Visuals to come...</StyledNoVisuals>}
      <VideosContainer>
        {!!videos.length && (
          <PageAnimationWrapper index={device?.isMobile() || isSinglePage ? 1 : 0}>
            {videos.map(({ url, caption, id }) => (
              <VideoSnippet
                url={url}
                caption={caption}
                id={id}
                key={id}
                additionalPaddingBottom={!photos.length}
              />
            ))}
          </PageAnimationWrapper>
        )}
      </VideosContainer>
      {isSinglePage ? (
        <SinglePageGalleryPhotos
          handleOpenLightbox={handleOpenLightbox}
          photos={photos}
          urlParams={URL_PARAMS}
        />
      ) : (
        <GalleryPhotos
          handleOpenLightbox={handleOpenLightbox}
          identifierPrefix="photos"
          photos={photos}
          urlParams={URL_PARAMS}
        />
      )}
      {lightboxOpen && openPhoto && portalContainer !== null && (
        <Portal portalContainer={portalContainer}>
          <Lightbox onClick={() => null}>
            <LightboxImageContainer onKeyDown={handlePressEsc} ref={containerRef} tabIndex={-1}>
              <CloseLightboxBtn onClick={handleCloseLightbox}>
                <CloseIcon height={CLOSE_ICON_SIZE} width={CLOSE_ICON_SIZE} />
                <ScreenReaderText>Close</ScreenReaderText>
              </CloseLightboxBtn>
              <SlideshowContainer>
                <ControlContainer>
                  <CaretContainerButton
                    onClick={handlePrevImage}
                    role="button"
                    data-testid="arrow-btn"
                    hide={currentIndex === 0}
                  >
                    <CaretV2Icon direction="left" height={22} width={22} />
                  </CaretContainerButton>
                </ControlContainer>

                <LightboxImageSection>
                  <LightboxImage
                    src={
                      URL_PARAMS
                        ? `${getZolaImageFromUuid(openPhoto.image_id as string)}?${URL_PARAMS}`
                        : `${getZolaImageFromUuid(openPhoto.image_id as string)}`
                    }
                    alt=""
                    className="center-block img-responsive photo"
                    data-testid="lightbox-img"
                    ref={imageRef}
                    loading={!inPreview ? 'lazy' : undefined}
                  />
                </LightboxImageSection>
                <ControlContainer>
                  <CaretContainerButton
                    hide={currentIndex === photos.length - 1}
                    onClick={handleNextImage}
                    role="button"
                    data-testid="arrow-btn"
                  >
                    <CaretV2Icon direction="right" height={22} width={22} />
                  </CaretContainerButton>
                </ControlContainer>
              </SlideshowContainer>
              <LightboxCaption>{openPhoto.caption}</LightboxCaption>
            </LightboxImageContainer>
          </Lightbox>
        </Portal>
      )}
    </GalleryPageContainer>
  );
};

export default Gallery;
